export default {
  methods: {
    drawWaveform() {
      const bufferLength = this.analyserNode.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);
      this.canvasContext.clearRect(
        0,
        0,
        this.$refs.waveformCanvas.width,
        this.$refs.waveformCanvas.height
      );
      const draw = () => {
        this.analyserNode.getByteTimeDomainData(dataArray);
        this.canvasContext.fillStyle = "rgb(255, 255, 255)";
        this.canvasContext.fillRect(
          0,
          0,
          this.$refs.waveformCanvas.width,
          this.$refs.waveformCanvas.height
        );
        this.canvasContext.lineWidth = 2;
        this.canvasContext.strokeStyle = "green";
        this.canvasContext.beginPath();
        const sliceWidth =
          (this.$refs.waveformCanvas.width * 1.0) / bufferLength;
        let x = 0;
        for (let i = 0; i < bufferLength; i++) {
          const v = dataArray[i] / 128.0;
          const y = (v * this.$refs.waveformCanvas.height) / 2;
          if (i === 0) {
            this.canvasContext.moveTo(x, y);
          } else {
            this.canvasContext.lineTo(x, y);
          }
          x += sliceWidth;
        }
        this.canvasContext.lineTo(
          this.$refs.waveformCanvas.width,
          this.$refs.waveformCanvas.height / 2
        );
        this.canvasContext.stroke();
      };
      this.drawInterval = setInterval(draw, 1000 / 60); // 60 frames per second
    },
  },
};
