<template>
  <div :style="ctfirstmodal || ctsecondmodal ? 'height:100vh' : ''">
    <div v-if="isLoading" class="spinnerstyle">
      <Spinner />
    </div>
    <div class="login-main-container" :style="testSubmit ? 'height:100vh' : ''">
      <div
        v-if="configuration && !testSubmit"
        class="h-100 d-flex  justify-content-center align-items-center"
      >
        <div
          class="login-main-sub-container position-relative"
          style="padding: 10px;"
        >
          <b-row class="m-0 w-100">
            <b-col class="p-0">
              <div
                class="w-100 d-flex justify-content-center align-items-baseline"
              >
                <img src="../assets/newimgs/GREYLOGO.svg" alt="" />
              </div>
            </b-col>
          </b-row>
          <b-row class=" m-0 w-100">
            <b-col class="p-0">
              <div
                style="height: calc(100vh - 152px);"
                class=" d-flex align-items-center flex-column mt-3 text-center testdetails-container"
              >
                <div
                  class="w-100 h-100 d-flex justify-content-center flex-column"
                >
                  <h1 class="login-rightcont-headtxt d-flex">
                    Communication Test
                  </h1>
                  <div class="mt-3" style="height: 95%;">
                    <h4 class="typingtest-completed-txt d-flex">
                      Describe the picture in your own words using keywords
                      given below
                    </h4>
                    <b-row class="m-0 w-100" style="height: 65%;">
                      <b-col cols="8" class="h-100">
                        <div
                          class="comunicatn-keywords-container d-flex mt-5 h-100"
                        >
                          <div class="comunicatn-test-image-container">
                            <img class="w-100 h-100" :src="imgSrc" alt="" />
                          </div>
                          <div
                            style="width: 60%;"
                            class="d-flex flex-column align-items-baseline ml-4"
                          >
                            <div class="ct-keyword-txt">
                              Keywords :
                            </div>
                            <div
                              class="d-flex align-items-center flex-wrap mt-3"
                            >
                              <div
                                class="ct-keywords-tags mr-2 mt-1"
                                v-for="(item, index) in keyWords"
                                :key="index"
                              >
                                {{ item }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-col>
                      <b-col cols="4" class="pr-4 h-100">
                        <div
                          class="comunicatn-right-container position-relative align-items-center justify-content-center mt-5 d-flex flex-column w-100 h-100"
                        >
                          <div class="">
                            <img
                              v-if="remainingtimeleft > 0"
                              src="../assets/newimgs/redMice.svg"
                              alt=""
                            />
                            <img
                              v-else
                              src="../assets/newimgs/redMiceCross.svg"
                              alt=""
                            />
                          </div>
                          <div
                            class="comunicatn-test-complete"
                            v-if="remainingtimeleft <= 0"
                          >
                            You have completed your test
                          </div>
                          <div class="mr-3 ml-3" style="height: 35%;">
                            <canvas
                              class="w-100 h-100"
                              ref="waveformCanvas"
                            ></canvas>
                          </div>
                          <div
                            v-if="remainingtimeleft > 0"
                            class="d-flex justify-content-center"
                            style="height: 40%;"
                          >
                            <div class="ct-recording-txt mt-2">
                              Recording...
                            </div>
                            <div
                              class="p-tooltip"
                              v-if="
                                remainingtimeleft <= 35 &&
                                  remainingtimeleft > 0 &&
                                  !isResult
                              "
                            >
                              The timer has started; please begin speaking...
                            </div>
                          </div>

                          <div style="height: 40%;" v-else>
                            <!-- GREEN -->
                            <div
                              @click="Speakertest()"
                              v-if="!startedHearing"
                              class="d-flex justify-content-center align-items-center  pointer"
                            >
                              <div class="ct-blue-txt mr-2">Hear Answer</div>
                              <div>
                                <img
                                  src="../assets/newui-img/audio-off.svg"
                                  alt=""
                                />
                              </div>
                            </div>
                            <div
                              v-else
                              @click="stopHearingAnswer()"
                              class="d-flex justify-content-center align-items-center pointer"
                            >
                              <div class="ct-blue-txt mr-2">
                                Stop hearing Answer
                              </div>
                              <div>
                                <img
                                  src="../assets/newui-img/audio-on2.svg"
                                  alt=""
                                />
                              </div>
                            </div>
                            <div v-if="remainingNoOfAttempts >= 1" class="">
                              <div class="d-flex align-items-center mt-3">
                                <div
                                  class="d-flex align-items-center pointer"
                                  @click="retryOrSubmit(false)"
                                >
                                  <div>
                                    <img
                                      src="../assets/newimgs/replay_black.svg"
                                      alt=""
                                    />
                                  </div>
                                  <div class="retrytxt ml-1">
                                    Retry
                                  </div>
                                </div>
                                <div class="ct-retry-lefts ml-2">
                                  {{ remainingNoOfAttempts }} attempts left
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="comunicatn-tst-timer">
                            <div
                              style="height: 85.7%;"
                              class="position-relative p-3 d-flex flex-column justify-content-around align-content-center"
                            >
                              <div
                                style=" position: absolute; left: 50%; transform: translate(-90%, -10%); top: -4%;filter: drop-shadow(0px 15px 30px rgba(122, 122, 122, 0.15));
                            "
                                class="d-flex justify-content-center align-items-center"
                              >
                                <div
                                  style=" width: 150px;height: 150px;padding: 5px; border-radius: 50%;background: #fff;display: grid;place-content: center;place-items: center;"
                                >
                                  <div
                                    class="typingtest-progress-container"
                                    :style="
                                      remainingtimeleft <= 10
                                        ? remainingtimeStylefinal
                                        : remainingtimeleft <= 22
                                        ? remaininghalftimeStyle
                                        : remainingtimeStyle
                                    "
                                  >
                                    <div
                                      v-if="remainingtimeleft > 0"
                                      class="typingtest-valueprogress"
                                    >
                                      <div class="time-txt">Time</div>
                                      <span id="timer">{{
                                        remainingtimeleft
                                      }}</span>
                                    </div>
                                    <div
                                      v-else
                                      class="typingtest-valueprogress"
                                    >
                                      <div>Time's up</div>
                                      <img
                                        class=""
                                        src="../assets/GreenTick.svg"
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <audio autoplay style="display: none;"></audio>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </div>
              <hr class="mb-0 mt-1" />
              <div
                class="p-3 d-flex align-items-center justify-content-between bluebtns"
              >
                <b-button
                  variant="outline-primary"
                  @click="$bvModal.show('leavetestmodal')"
                  >Leave Test</b-button
                >
                <b-button
                  :disabled="remainingtimeleft > 0"
                  variant="primary"
                  @click="retryOrSubmit(true)"
                  >Submit</b-button
                >
              </div>
            </b-col>
          </b-row>
        </div>
        <b-modal
          id="leavetestmodal"
          size="md"
          centered
          hide-footer
          hide-header
          no-close-on-backdrop
          :no-close-on-esc="true"
        >
          <div
            class="newmodalheadings mt-3 d-flex align-items-center justify-content-center"
          >
            Alert !
          </div>
          <div
            @click="$bvModal.hide('leavetestmodal')"
            class="modalcloseicon pointer"
          >
            <img
              class="rotate-on-hover"
              src="../assets/newimgs/closeicon.svg"
              alt=""
            />
          </div>
          <div
            class="d-flex flex-column align-items-center justify-content-center mt-4 mb-4"
          >
            <div>
              <img src="../assets/newui-img/leavetest.svg" alt="" />
            </div>
            <div class="newui-modal-header mt-4">
              Are you sure you want to leave the test ?
            </div>
            <div class="newui-modal-bodytxt mt-3">
              Please note this action cannot be undone
            </div>
            <div class="bluebtns mt-5">
              <b-button
                class="mr-3 pl-4 pr-4"
                variant="outline-primary"
                @click="$bvModal.hide('leavetestmodal')"
                >Cancel</b-button
              >
              <b-button variant="primary" class="pl-4 pr-4" @click="leave()"
                >Leave Test</b-button
              >
            </div>
          </div>
        </b-modal>
      </div>
      <div
        v-if="testSubmit"
        class="h-100 d-flex  justify-content-center align-items-center"
      >
        <div
          class="login-main-sub-container position-relative"
          style="padding: 10px;"
        >
          <b-row class="m-0 w-100">
            <b-col class="p-0">
              <div
                class="w-100 d-flex justify-content-center align-items-baseline"
              >
                <img src="../assets/newimgs/GREYLOGO.svg" alt="" />
              </div>
            </b-col>
          </b-row>
          <b-row class=" m-0 w-100">
            <b-col class="p-0">
              <div
                style="height: calc(100vh - 100px);"
                class="d-flex align-items-center flex-column mt-3 mb-3 text-center testdetails-container"
              >
                <div class="w-75 d-flex justify-content-center flex-column ">
                  <h1 class="login-rightcont-headtxt pt-3">
                    Completed Successfully
                  </h1>
                  <div class="mt-3">
                    <!-- <h4 class="typingtest-completed-txt">
                      You have completed the typing test
                    </h4> -->
                    <div
                      class="mt-4 d-flex align-items-center justify-content-center flex-column"
                    >
                      <div class="w-50">
                        <div
                          class="mt-3 typingtest-completed-cards p-3 position-relative"
                        >
                          <img
                            class=""
                            src="../assets/newui-img/cmpltdsuccess.svg"
                            alt=""
                          />
                          <div>
                            <div class="completed-box-heading pt-4 mt-3">
                              You have successfully completed your communication
                              test
                            </div>

                            <!-- <img
                              src="../assets/newimgs/typingtest/completed.svg"
                              alt=""
                            /> -->
                          </div>

                          <div
                            class="mb-3 d-flex align-items-center justify-content-center bluebtns"
                          >
                            <b-button
                              @click="cmCompleteBtn()"
                              variant="primary"
                              class="mt-5 pl-5 pr-5"
                              >Done</b-button
                            >
                          </div>

                          <!-- <h3 class="typingtest-card-head">Total Time Taken</h3> -->
                          <!-- <span class="testdetails-simpledetails-subtxt">
                            60 seconds
                          </span> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-col></b-row
          >
        </div>
      </div>
    </div>

    <div v-if="!testSubmit">
      <b-modal
        id="ct-firstmodal"
        size="lg"
        centered
        hide-footer
        hide-header
        no-close-on-backdrop
        :no-close-on-esc="true"
      >
        <div
          class="newmodalheadings d-flex align-items-center justify-content-center"
        >
          Your next test is Communication Test
        </div>
        <div
          class="d-flex flex-column align-items-center justify-content-center"
        >
          <div class="newmodal-body-content mt-4">
            You have to describe the picture in your own words using the
            keywords given. The timer <br />
            will be set for 45 seconds. You have to start once you enter the
            screen.
          </div>
          <div class="mt-3">
            <img src="../assets/newui-img/comunicationmodalicon.svg" alt="" />
          </div>
          <div class="ct-makesure-modal-txt mt-4">
            Make sure your microphone is working before starting the test
          </div>
          <div class="ct-makesure-modal-txt mt-4">
            Please speak in a loud and clear voice.
          </div>
          <div
            ref="dropdown"
            class="mt-4 d-flex justify-content-center align-items-center w-100"
          >
            <div class="ct-modal-icons-txt mr-5">
              <img src="../assets/newimgs/syscheckup/microphone.svg" alt="" />
              <span class="ml-2">Microphone</span>
            </div>
            <div class="position-relative w-50">
              <div
                @click="toggleDropdown()"
                class="w-100 ct-dropdown d-flex align-items-center justify-content-between position-relative pointer"
              >
                <div class="ct-dropdown-head text-truncate" style="width: 95%">
                  {{ AudioId }}
                </div>
                <div style="width: 5%;">
                  <img
                    src="../assets/newimgs/syscheckup/downarrow.svg"
                    alt=""
                  />
                </div>
              </div>
              <div
                v-if="camera"
                class="sc-dropdown w-100 pointer"
                @click.stop="handleClick"
              >
                <b-form-group class="w-100">
                  <b-form-radio-group
                    class="sc-add-drop pointer w-100"
                    id="mic-list"
                    v-model="AudioId"
                    :options="AudioDevice"
                    value-field="text"
                    @change="handleSuccessaudio"
                    name="radio-btn-stacked"
                    buttons
                    stacked
                  ></b-form-radio-group>
                </b-form-group>
              </div>
            </div>
          </div>
          <div
            :class="selecteddeviceOn ? 'hidewarning' : ''"
            class="mt-4 d-flex justify-content-center align-items-center w-100"
          >
            <div>
              <canvas
                v-show="micchecking || !miccheckingfirst"
                ref="waveformCanvas"
                width="400"
                height="100"
              ></canvas>
              <img
                width="400"
                height="100"
                v-if="miccheckingfirst"
                src="../assets/newimgs/waves.svg"
                alt=""
              />
            </div>
            <div class="bluebtns ml-3">
              <!-- use btn name stop checking while the audio is checked -->
              <b-button
                variant="primary"
                class="pl-4 pr-4"
                @click="micchecking ? stoptestmic() : testmic()"
                >{{ micchecking ? "Stop Checking" : "Check Audio" }}</b-button
              >
            </div>
          </div>
          <div v-if="micchecking && speaking">Playing back your voice...</div>
          <div v-if="micchecking && !speaking">Please speak something...</div>
          <div
            :class="selecteddeviceOn ? '' : 'hidewarning'"
            class="mt-4 w-100"
          >
            <div class="newmodal-body-content mt-4">
              Your microphone is muted; please check your system for
              troubleshooting.
            </div>
          </div>
          <div class="bluebtns mt-5">
            <b-button
              variant="primary"
              class="pl-4 pr-4"
              :disabled="selecteddeviceOn"
              @click="starttest()"
              >Start Test</b-button
            >
          </div>
        </div>
      </b-modal>
      <b-modal
        id="teststarts"
        centered
        hide-footer
        hide-header
        no-close-on-backdrop
        :no-close-on-esc="true"
      >
        <div
          class="newmodalheadings mt-5 d-flex align-items-center justify-content-center"
        >
          Communication Test will start in
        </div>
        <div
          class="d-flex flex-column align-items-center justify-content-center mt-4 mb-4"
        >
          <div
            style=" width: 150px;height: 150px;padding: 5px; border-radius: 50%;background: #fff;display: grid;place-content: center;place-items: center;"
          >
            <div
              class="typingtest-progress-container"
              :style="progressBarStyle"
            >
              <div class="typingtest-valueprogress">{{ timeleft }} sec</div>
            </div>
          </div>
        </div>
      </b-modal>
      <b-modal id="locationmodal" centered hide-footer hide-header size="xl">
        <div
          class="newmodalheadings justify-content-center text-center  d-flex align-items-center"
        >
          Enable System Permissions
        </div>
        <div @click="getDevices()" class="modalcloseicon pointer">
          <img
            class="rotate-on-hover"
            src="../assets/newimgs/closeicon.svg"
            alt=""
          />
        </div>
        <div class="mt-5 ml-3">
          <b-row>
            <b-col>
              <div class="mt-2 step-subtext">
                1. Please Click on view site information to allow all
                permissions
              </div>
              <div class="mt-2">
                <img src="../assets/images/step1.svg" alt="" />
              </div>
            </b-col>
            <b-col>
              <div class="mt-2 step-subtext">
                2. Please Enable all permissions
              </div>
              <div class="mt-2">
                <img src="../assets/images/step2.svg" alt="" />
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="mt-4 ml-3">
          <b-row>
            <b-col>
              <div class="mt-2 step-subtext">
                3. After enabling Permissions, Reload the page
              </div>
              <div class="mt-2">
                <img src="../assets/images/step3.svg" alt="" />
              </div>
            </b-col>
          </b-row>
        </div>
        <div
          class="bluebtns d-flex align-items-center justify-content-center mt-4"
        >
          <b-button variant="primary" class="px-5" @click="getDevices()"
            >Okay</b-button
          >
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import communicationTest from "../mixins/communicationTest";
export default {
  data() {
    return {
      property: "value",
    };
  },
  mixins: [communicationTest],
};
</script>

<style scoped>
@media only screen and (max-width: 1024px) {
  #ct-firstmodal___BV_modal_outer_ {
    display: none !important;
  }
}
.hidewarning {
  display: none !important;
}

.p-tooltip {
  position: absolute;
  top: 255px;
  border-radius: 5px;
  border: 1px solid #4c78ce;
  background: #fff;
  box-shadow: 0px 15px 30px 0px rgba(122, 122, 122, 0.2);
  color: #4c78ce;
  font-size: 12px;
  font-weight: 600;
  white-space: nowrap;
  padding: 10px;
}
.p-tooltip::before {
  content: "";
  position: absolute;
  left: 135px;
  top: -5px;
  transform: translateX(-50%) rotate(45deg);
  width: 10px;
  height: 10px;
  border: 1px solid transparent;
  border-left-color: #4c78ce;
  border-top-color: #4c78ce;
  background-color: white;
  z-index: 1;
}

.ct-retry {
  color: #72738e;
  font-size: 14px;
  font-weight: 500;
  text-decoration-line: underline;
}

.ct-green-txt {
  color: #038e00;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 13px;
}

.ct-blue-txt {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 13px;
  color: #20639b;
}

.comunicatn-rules-container {
  height: 200px;
  border-radius: 14px;
  background: #fff;
}

.ct-rules-head {
  color: #333;
  font-size: 12px;
  font-weight: 600;
}

.comunicatn-test-complete {
  border-radius: 8px;
  border: 0.8px solid #269c32;
  background: #fdfdfd;
  z-index: 1000;
  padding: 5px 10px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #269c32;
}
</style>
<!-- <style scoped>
.communication-after-image-container {
  width: 50%;
  height: calc(100vh - 240px);
  background: #ffffff;
  box-shadow: 1px 0px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(25px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 5px;
  padding-right: 2em;
}
.communication-after-left-container {
  /* background: #F3F5F4;; */
  /* background: rgba(243, 245, 244, 0.75); */
  background: url("../assets/frosty.png") no-repeat !important;
  /* box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 ); */
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 5px;
  width: 50%;
  padding: 50px 60px 30px 100px;
  background-size: cover !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cmTestDetailsContainer {
  padding: 0px 30px;
  /* height: calc(100vh - 130px); */
  background: url(/img/frosty.39e8805f.png),
    linear-gradient(
      112.99deg,
      rgba(255, 255, 255, 0.5) -8.5%,
      rgba(255, 252, 252, 0) 98.16%
    );
  /* background: #f4f4f4; */
  backdrop-filter: blur(25px);
  border-radius: 5px;
  width: 70%;
  background-size: cover;
}
.cmTestCentre {
  padding: 0px 50px 8px 50px;
}
.cmTestTimer {
  /* height: calc(100vh - 130px); */
  /* box-shadow: 1px 0px 4px rgba(0, 0, 0, 0.25); */
  width: 30%;
  background-color: var(--bg-white);
  border-radius: 5px;
}
.cmTotalTime {
  /* margin-top: 17vh; */
  font-weight: 400;
  font-size: 23px;
  color: #72738e;
}
.timerdiv {
  width: 200px;
  height: 200px;
  border: 11px solid #ffffff;
  box-shadow: inset 0px 0px 15px rgb(0 0 0 / 35%);
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cmTestTimeUp {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 23px;
  color: #72738e;
}
.heightWhenIndicatorPresernt {
  height: 104px;
}
.cmTestHeading {
  width: 240px;
  height: 33px;
  margin: 15px 0px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  color: #72738e;
}
.cmTestSecondaryHeading {
  /* width: 476px;
  height: 21px; */
  margin: 15px 0px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #72738e;
}
.cmTestKeyWords {
  height: 20px;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  padding: 17px 34px;
  color: #696969;
}
.cmTestKeywordContainer {
  height: 53px;
  background: #ffffff;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 2px;
  margin-bottom: 10px;
}
fieldset.scheduler-border {
  padding: 0 1.4em 1.4em 1.4em !important;
  margin: 0 0 1.5em 0 !important;
  -webkit-box-shadow: 0px 0px 0px 0px #000;
  box-shadow: 0px 0px 0px 0px #000;
  background: #fafafa;
  border: 1px solid #c8c8c8;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
}

legend.scheduler-border {
  font-size: 1.2em !important;
  font-weight: bold !important;
  text-align: left !important;
  width: auto;
  padding: 0 10px;
  border-bottom: none;
}
.scheduler-border {
  font-size: 12px;
  background: white;
  color: #696969;
  margin-top: 100px;
}
.cmAttempts {
  width: 29px;
  height: 18px;
  margin-left: 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  color: #72738e;
}
.cmTimeIndicator {
  margin: 20px 0px 20px 0px !important;
}

.cmTestPicture {
  width: 325px;
  height: 235px;
  margin-bottom: 10px;
}
.ansHgt {
  height: 125px;
}
.cmCompleteContainer {
  width: 30%;
  background: #ffffff;
  box-shadow: 0px 6px 34px rgba(215, 216, 222, 0.69);
  border-radius: 10px;

  height: calc(100vh - 130px);
}
.cmCompleted {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  text-align: center;

  color: #72738e;
}
.cmComplete {
  width: 280px;
  height: 33px;
  width: 100%;
  padding: 25px 0px;
  padding: 45px 0px;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;

  color: #72738e;
}
.cmFinalMessage {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  text-align: center;

  color: #72738e;
}
.imgDiv {
  margin: 75px 0px 41px 75px;
}
.cmKeyWords {
  padding: 3px 11px;

  margin: 0px 6px;
  background: #72738e;
  color: white;
  border-radius: 5px;
}
.hideScroll::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.cmTestControllButtons {
  height: 70px;
}
.mcHear {
  /*  */
  margin-top: 20px;
}
.mcHear:hover {
  cursor: pointer;
}
.retryBtn:hover {
  cursor: pointer;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.animatedBackground {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: 96px;
  position: relative;
  margin: 15px;
  transform: translateY(75%);
}
.imagePlaceholder {
  width: 260px;
}
.contentPlaceholder {
  width: 500px;
}
.backgroundMasker {
  background: #fff;
  position: absolute;
}

/* Every thing below this is just positioning */

.backgroundMasker.headerTop,
.backgroundMasker.headerBottom,
.backgroundMasker.subheaderBottom {
  top: 0;
  left: 40px;
  right: 0;
  height: 10px;
}

.backgroundMasker.headerLeft,
.backgroundMasker.subheaderLeft,
.backgroundMasker.headerRight,
.backgroundMasker.subheaderRight {
  top: 10px;
  left: 40px;
  height: 8px;
  width: 10px;
}

.backgroundMasker.headerBottom {
  top: 18px;
  height: 6px;
}

.backgroundMasker.subheaderLeft,
.backgroundMasker.subheaderRight {
  top: 24px;
  height: 6px;
}

.backgroundMasker.headerRight,
.backgroundMasker.subheaderRight {
  width: auto;
  left: 60%;
  right: 0;
}

.backgroundMasker.subheaderRight {
  left: 46%;
}

.backgroundMasker.subheaderBottom {
  top: 30px;
  height: 10px;
}

.backgroundMasker.contentTop,
.backgroundMasker.contentSecondLine,
.backgroundMasker.contentThirdLine,
.backgroundMasker.contentSecondEnd,
.backgroundMasker.contentThirdEnd,
.backgroundMasker.contentFirstEnd {
  top: 40px;
  left: 0;
  right: 0;
  height: 6px;
}

.backgroundMasker.contentTop {
  height: 20px;
}

.backgroundMasker.contentFirstEnd,
.backgroundMasker.contentSecondEnd,
.backgroundMasker.contentThirdEnd {
  width: auto;
  left: 76%;
  right: 0;
  top: 60px;
  height: 8px;
}

.backgroundMasker.contentSecondLine {
  top: 68px;
}

.backgroundMasker.contentSecondEnd {
  left: 84%;
  top: 74px;
}

.backgroundMasker.contentThirdLine {
  top: 82px;
}

.backgroundMasker.contentThirdEnd {
  left: 60%;
  top: 88px;
}

.imageLeft {
  left: 0;
  height: 96px;
  width: 30%;
}

.imageRight {
  right: 0;
  height: 96px;
  width: 30%;
}
.sun {
  top: 10%;
  left: 35%;
  height: 15px;
  width: 15px;
  border-radius: 50%;
}

.smallMountain:after {
  top: 50px;
  left: 82px;
  content: "";
  position: absolute;
  border: 20px solid transparent;
  border-bottom: 20px solid #fff;
}
.bigMountain:after {
  top: 20px;
  left: 100px;
  content: "";
  position: absolute;
  border: 35px solid transparent;
  border-bottom: 35px solid #fff;
}
.shimmer {
  /* height: 70vh; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* box-shadow: 0px 6px 34px rgb(215 216 222 / 69%); */
}
.completedSuccess {
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  color: #72738e;
}
.cmtestCmpt {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  text-align: center;

  color: #72738e;
}
.dosimage {
  width: 40%;
  background: #ffffff;
  box-shadow: 1px 0px 4px rgb(0 0 0 / 25%);
  backdrop-filter: blur(25px);
  border-radius: 5px;
} 
</style>
-->
