<template>
  <div>
    <div v-if="isLoading" class="spinnerstyle">
      <Spinner />
    </div>
    <div v-if="!testSubmit" class="mobileScreen-main-container">
      <MobileHeader />
      <div class="mobileScreen-main-innerContainer">
        <div v-if="!communicationTestAttempts && !startTest">
          <div class="mobileScreen-TD-head m-3">
            Next is Communication Test
          </div>
          <hr />

          <div class="m-3 mt-4 d-flex flex-column justify-content-between">
            <div class="mobileScreen-CT-mainBody-2">
              <div class="mobileScreen-CT-head">
                You have to describe the picture in your own words using the
                keywords given. The timer will be set for 45 seconds. You have
                to start once you enter the screen.
              </div>

              <div
                class="mt-4 d-flex align-items-center justify-content-center w-100"
              >
                <img
                  src="../../assets/mobileScreenIcons/com-test-main-image.svg"
                  alt=""
                />
              </div>

              <div class="mt-4">
                <div class="mobileScreen-CT-heading">Test microphone</div>
                <div class="mobileScreen-CT-record-new-text mt-2">
                  Please speak in a loud and clear voice.
                </div>
                <div
                  class="mt-4 mb-4 text-center"
                  v-if="!micchecking && miccheckingfirst"
                >
                  <div @click="testmic()">
                    <!--  -->
                    <img
                      src="../../assets/mobileScreenIcons/Record.svg"
                      alt=""
                    />
                  </div>
                  <div class="mobileScreen-CT-testAgain-text">
                    Start checking
                  </div>
                </div>
                <div
                  class="mt-4 text-center"
                  v-show="micchecking || !miccheckingfirst"
                >
                  <div class="mt-2">
                    <canvas
                      ref="waveformCanvas"
                      width="300"
                      height="80"
                    ></canvas>
                  </div>

                  <div
                    class="mb-5"
                    v-if="!miccheckingfirst && togglestart"
                    @click="testmic(), (togglestart = !togglestart)"
                  >
                    <div class="mt-2">
                      <!-- pause image -->
                      <img
                        src="../../assets/mobileScreenIcons/Play.svg"
                        alt=""
                      />
                    </div>

                    <div class="mobileScreen-CT-testAgain-text">
                      Start checking
                    </div>
                  </div>
                  <div
                    v-else
                    @click="stoptestmic(), (togglestart = !togglestart)"
                    class="mb-5"
                  >
                    <div class="mt-2">
                      <!-- pause image -->
                      <img
                        src="../../assets/mobileScreenIcons/Pause.svg"
                        alt=""
                      />
                    </div>
                    <div class="mobileScreen-CT-testAgain-text">
                      Stop checking
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-flex">
              <div>
                <img
                  src="../../assets/mobileScreenIcons/mobileScreen-infoIcon.svg"
                  alt=""
                />
              </div>
              <div class="mobileScreen-CT-record-text ml-3">
                Make sure your microphone is working before starting the test.
              </div>
            </div>
          </div>
        </div>

        <div v-if="communicationTestAttempts && !startTest">
          <div>
            <div class="m-3 d-flex align-items-center justify-content-between">
              <div>
                <div class="mobileScreen-TD-head">Communication Test</div>
                <div class="mobileScreen-CT-attempts-new mt-1">
                  {{ testDetails.config.communicationTestAttempts }} Attempts
                </div>
              </div>

              <div
                :class="
                  remainingtimeleft > 25
                    ? 'mobileScreen-CT-timing-container'
                    : remainingtimeleft > 10
                    ? 'mobileScreen-CT-timing-container-yellow'
                    : 'mobileScreen-CT-timing-container-red'
                "
                class="text-center"
              >
                <div>
                  <img
                    src="../../assets/mobileScreenIcons/timer-stopwatch.svg"
                    alt=""
                  />
                </div>
                <div v-if="remainingtimeleft > 0">
                  <span class="mobileScreen-CT-stopwatch-number">{{
                    remainingtimeleft
                  }}</span>
                  <span class="mobileScreen-CT-stopwatch-font"> Sec </span>
                </div>
                <div v-else>
                  <span class="mobileScreen-CT-stopwatch-font">
                    Time's up
                  </span>
                </div>
              </div>
            </div>
            <hr />

            <div class="mobileScreen-CT-mainBody m-3">
              <div class="mobileScreen-CT-subText">
                Describe the picture in your own words using keywords listed
                below.
              </div>

              <div class="mt-4 text-center">
                <img
                  :src="imgSrc"
                  alt=""
                  style="width: 328px; height: 129px; border-radius: 10px"
                />
              </div>

              <div class="mt-4">
                <div class="newkeyword-font">Keywords</div>
                <div class="d-flex mt-2 mobileScreen-CT-keywords-overflow">
                  <div
                    class="d-flex mr-3 mt-1"
                    v-for="(words, i) in keyWords"
                    :key="i"
                  >
                    <div class="mobileScreen-CT-keywords">
                      {{ words }}
                    </div>
                  </div>
                </div>
              </div>
              <audio autoplay style="display: none"></audio>
              <div class="text-center">
                <canvas width="300" height="80" ref="waveformCanvas"></canvas>
              </div>
              <div
                v-if="remainingtimeleft > 0"
                class="mt-5 mb-5 text-center position-relative"
              >
                <div>
                  <img src="../../assets/mobileScreenIcons/Record.svg" alt="" />
                </div>
                <div class="mobileScreen-CT-testAgain-text">Recording</div>
                <div
                  class="p-tooltip"
                  v-if="
                    remainingtimeleft <= 35 &&
                      remainingtimeleft > 0 &&
                      !isResult
                  "
                >
                  The timer has started; please begin speaking...
                </div>
              </div>

              <div class="mt-5 mb-5 text-center" v-else>
                <div class="mt-2"></div>

                <div
                  v-if="!startedHearing"
                  class="mt-1 d-flex align-items-center justify-content-center"
                >
                  <div @click="Speakertest()" class="CT-hearing-font">
                    Hear Answer
                  </div>
                  <img
                    @click="Speakertest()"
                    src="../../assets/mobile-newUI-imgs/start-hearing.svg"
                    alt=""
                    class="ml-3"
                  />
                  <!-- pause image -->
                </div>
                <div
                  v-else
                  class="mt-1 d-flex align-items-center justify-content-center"
                >
                  <div @click="stopHearingAnswer()" class="CT-hearing-font">
                    Stop Hearing
                  </div>
                  <!-- pause image -->
                  <img
                    @click="stopHearingAnswer()"
                    src="../../assets/mobile-newUI-imgs/stop-hearing.svg"
                    alt=""
                    class="ml-3"
                  />
                </div>

                <div
                  v-if="remainingNoOfAttempts >= 1"
                  class="d-flex justify-content-center mt-4"
                >
                  <div
                    @click="retryOrSubmit(false)"
                    class="CT-hearing-font pointer"
                  >
                    <img
                      src="../../assets/mobile-newUI-imgs/retry-img.svg"
                      class="mr-2 mb-1"
                      alt=""
                    />
                    Retry
                  </div>
                  <div class="ml-4 mobileScreen-TD-yellowAttempts">
                    {{ remainingNoOfAttempts }} attempts left
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="!startTest"
          class="mobileScreen-TD-footer d-flex align-items-center justify-content-between bluebtns"
        >
          <b-button
            class="mobileScreen-leaveBtn mr-3"
            @click="$bvModal.show('mobileLeaveTest')"
            >Leave Test</b-button
          >
          <template>
            <b-button
              v-if="!communicationTestAttempts && !startTest"
              class="mobileScreen-startBtn"
              @click="
                starttest(),
                  (startTest = true),
                  (communicationTestAttempts = false)
              "
              >Start Test</b-button
            >
            <b-button
              v-else
              class="mobileScreen-startBtn"
              :disabled="remainingtimeleft > 0"
              @click="retryOrSubmit(true)"
              >Submit</b-button
            >
          </template>
        </div>
          <!-- for timer -->
      <div
        class="mobileScreen-CT-mainBody d-flex align-items-center justify-content-center flex-column"
        v-if="startTest"
      >
        <div class="mobileScreen-progress-mainContainer-head mb-5">
          Communication Test will starts in
        </div>
        <div class="mobileScreen-progress-mainContainer">
          <div
            class="mobileScreen-progress-main-innerContainer"
            :style="progressBarStyleMobile"
          >
            <div
              class="mobileScreen-progress-container d-flex align-items-center justify-content-center"
            >
              <div
                v-if="timeleft > 0"
                class="mobileScreen-valueprogress d-flex align-items-center justify-content-center flex-column"
              >
                <div class="mobileScreen-time-txt">{{ timeleft }}</div>
                <span class="ml-2 mobileScreen-seconds-txt">Seconds</span>
              </div>
              <div v-else class="typingtest-valueprogress text-center">
                <div>Time's up</div>
                <img class="" src="../../assets/GreenTick.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>

    
      <!-- leave error modal starts from here -->
      <b-modal
        id="mobileLeaveTest"
        centered
        hide-footer
        hide-header
        class="p-0"
      >
        <div
          class="d-flex align-items-center justify-content-center flex-column"
        >
          <div>
            <img
              src="../../assets/mobile-newUI-imgs/new-warning-icon.svg"
              alt=""
            />
          </div>

          <div class="mt-4 mobileScreen-modal-head">Alert!</div>

          <div class="mt-3 mobileScreen-modal-subhead text-center">
            "Are you sure you want to leave the test ?" , Please note this
            action cannot be undone.
          </div>

          <div
            class="mt-4 bluebtns d-flex align-items-center justify-content-center flex-column w-100"
          >
            <div class="w-50">
              <b-button
                @click="$bvModal.hide('mobileLeaveTest')"
                class="mobileScreen-startBtn w-100"
                >Cancel</b-button
              >
            </div>
            <div class="w-50">
              <b-button
                @click="leave()"
                class="mobileScreen-leaveBtn mt-3 w-100"
                >Leave Test</b-button
              >
            </div>
          </div>
        </div>
      </b-modal>
    </div>
    <div v-if="testSubmit">
      <div class="mobileScreen-main-container">
        <MobileHeader />

        <div
          class="mobileScreen-main-innerContainer d-flex align-items-center justify-content-center flex-column"
        >
          <div class="mobilescreen-completed">Completed Successfully</div>

          <div
            class="mt-4 text-center CT-completed-decription"
            style="width: 93%"
          >
            You have successfully completed your communication test.
          </div>

          <div class="mt-4">
            <img
              src="../../assets/mobileScreenIcons/completed-icon.svg"
              alt=""
            />
          </div>

          <div @click="cmCompleteBtn()" class="mt-5 CT-done-font pointer">
            Done
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import communicationTest from "../../mixins/communicationTest";
export default {
  name: "MCommunicationTest",
  mixins: [communicationTest],
};
</script>

<style scoped>
.p-tooltip {
  position: absolute;
  top: -34px;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  border: 1px solid #4c78ce;
  background: #fff;
  box-shadow: 0px 15px 30px 0px rgba(122, 122, 122, 0.2);
  color: #4c78ce;
  font-size: 12px;
  font-weight: 600;
  white-space: nowrap;
  padding: 10px;
}

.p-tooltip::before {
  content: "";
  position: absolute;
  left: 137px;
  top: 33px;
  transform: translateX(-50%) rotate(45deg);
  width: 10px;
  height: 10px;
  border: 1px solid transparent;
  border-right-color: #4c78ce;
  border-bottom-color: #4c78ce;
  background-color: white;
  z-index: 1;
}
</style>
