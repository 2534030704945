import {
  getCommunicationTestDetails,
  postCommunicationTestTesponse
} from "./../apiFunction";
import MobileHeader from "./../components/mobileView/MobileHeader.vue";
import { mapGetters } from "vuex";
import drawWaveForm from "./drawWaveForm";
import Spinner from "./../components/fixedcomponents/Spinner.vue";
export default {
  name: "communicationTest",
  mixins: [drawWaveForm],
  data() {
    return {
      togglestart: false,
      communicationTestAttempts: false,
      startTest: false,
      isResult: false,
      startedHearing: false,
      remainingtimeInterval: null,
      remainingtimeleft: 45,
      remainingtimeValue: 0,
      isLoading: false,
      timeleft: 4,
      progressInterval: null,
      progressValue: 20,
      configuration: false,
      miccheckingfirst: true,
      analyserNode: null,
      audioContext: null,
      micchecking: false,
      speaking: true,
      sounddetected: false,
      selecteddeviceOn: false,
      selecteddeviceid: null,
      startcmtest: false,
      timerInterval: null,
      camera: false,
      AudioId: "",
      AudioDevice: [],
      SpeakerId: "",
      OutputDevice: [],
      showRelogin: null,
      RecordedAudio: "",
      recordedfile: null,
      audiostream: null,
      showTop: false,
      submit: false,
      recStarted: false,
      runtimeTranscription: "",
      transcription: [],
      lang: "en-US",
      started: true,
      keyWords: "",
      remainingNoOfAttempts: 0,
      imgSrc: "",
      recognition: null,
      recognizing: false,
      ctfirstmodal: false,
      ctsecondmodal: false,
      finalTranscription: ""
    };
  },
  computed: {
    ...mapGetters("sectionVUEX", {
      testDetails: "getTestDetails",
      userDetials: "getUserDetails",
      testSubmit: "gettestSubmit",
      uuid: "getUUID"
    }),
    progressBarStyleMobile() {
      return {
        background: `conic-gradient(
            #4c78ce ${this.progressValue * 3.6}deg,
            #fff ${this.progressValue * 3.6}deg
          )`
      };
    },
    progressBarStyle() {
      return {
        background: `conic-gradient(
            #20639b ${360 - this.progressValue * 3.6}deg,
            #cadcff ${360 - this.progressValue * 3.6}deg
          )`
      };
    },
    remaininghalftimeStyle() {
      return {
        background: `conic-gradient(
            #FFC145 ${360 - this.remainingtimeValue * 3.6}deg,
            #cadcff ${360 - this.remainingtimeValue * 3.6}deg
            )`
      };
    },
    remainingtimeStyle() {
      return {
        background: `conic-gradient(
            #68B15C ${360 - this.remainingtimeValue * 3.6}deg,
            #cadcff ${360 - this.remainingtimeValue * 3.6}deg
            )`
      };
    },
    remainingtimeStylefinal() {
      return {
        background: `conic-gradient(
            red ${360 - this.remainingtimeValue * 3.6}deg,
            #cadcff ${360 - this.remainingtimeValue * 3.6}deg
            )`
      };
    }
  },
  components: {
    Spinner,
    MobileHeader
  },
  created() {
    history.pushState(null, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
    this.getCmTestDeatils();
  },
  mounted() {
    window.addEventListener("beforeunload", this.handleBeforeUnload);
    this.$bvModal.show("ct-firstmodal");
    this.ctfirstmodal = true;
    setTimeout(() => {
      this.getDevices();
    }, 500);
    this.timerInterval = setInterval(() => {
      this.isMicrophoneMuted();
    }, 1000);
    if (!("webkitSpeechRecognition" in window)) {
      this.upgrade();
    } else {
      const MySpeechRecognition = function() {
        const SpeechRecognition =
          window.SpeechRecognition || window.webkitSpeechRecognition;
        return new SpeechRecognition();
      };
      this.recognition = new MySpeechRecognition();
      this.recognition.continuous = true;
      this.recognition.lang = this.lang;
      this.recognition.interimResults = true;
    }
  },
  beforeDestroy() {
    clearInterval(this.timerInterval);
    clearInterval(this.progressInterval);
    clearInterval(this.drawInterval);
    clearInterval(this.remainingtimeInterval);
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
    this.recognition.stop();
    this.audioContext.close();
  },
  watch: {
    testSubmit() {
      if (this.testSubmit) {
        this.recognition.stop();
      }
    },
    remainingtimeleft() {
      if (this.remainingtimeleft <= 0) {
        clearInterval(this.remainingtimeInterval);
        this.recognition.stop();
        this.stoppaudioStream();
      }
    }
  },
  methods: {
    handleBeforeUnload() {
      if (this.configuration && !this.testSubmit) {
        window.localStorage.setItem("reload", true);
        window.onbeforeunload = null;
        return "Are you sure you want to leave this window?";
      }
    },
    starttest() {
      this.recognition.stop();
      this.$bvModal.hide("ct-firstmodal");
      this.ctfirstmodal = false;
      this.$bvModal.show("teststarts");
      this.ctsecondmodal = true;
      setTimeout(() => {
        this.$bvModal.hide("teststarts");
        this.ctsecondmodal = false;
        this.configuration = true;
        this.startTest = false;
        this.communicationTestAttempts = true;
        clearInterval(this.progressInterval);
        this.startRecording();
        this.startAudioStream();
      }, 5000);
      this.progressInterval = setInterval(() => {
        this.progressValue = this.progressValue + 20;
        this.timeleft--;
      }, 1000);
      this.stoptestmic();
    },
    handleSuccessaudio() {
      this.camera = false;
      const device = this.AudioDevice.find(
        (device) => device.text === this.AudioId
      );
      let micselected = device.value;
      this.selecteddeviceid = micselected;
      this.audiodeviceselection = micselected
        ? {
            deviceId: micselected
          }
        : true;

      navigator.mediaDevices.getUserMedia({
        audio: this.audiodeviceselection
      });
      this.isMicrophoneMuted();
    },
    toggleDropdown() {
      this.camera = !this.camera;
      if (this.camera) {
        document.addEventListener("click", this.closeDropdown);
      } else {
        document.removeEventListener("click", this.closeDropdown);
      }
    },
    handleClick(event){
      const clickedOption = event.target.innerText;
      if(clickedOption===this.AudioId){
        this.camera=false;
      }
    },
    closeDropdown(event) {
      if (!this.$refs.dropdown.contains(event.target)) {
        this.camera = false;
        document.removeEventListener("click", this.closeDropdown);
      }
    },
    stoptestmic() {
      this.recognition.stop();
      this.speaking = true;
      this.sounddetected = false;
      clearInterval(this.drawInterval);
      if (this.audioContext) {
        this.audioContext.close().then(() => {
          this.micchecking = false;
        });
      }
    },
    async testmic() {
      this.recognition.start();
      setTimeout(() => {
        if (!this.sounddetected) {
          this.speaking = false;
        }
      }, 4000);
      this.recognition.addEventListener("soundstart", () => {
        this.sounddetected = true;
        this.speaking = true;
        console.log("Some sound is being received");
      });
      this.miccheckingfirst = false;
      this.audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
      const constraints = {
        audio: {
          deviceId: this.selectedAudioDeviceId
        }
      };
      try {
        this.audioStream = await navigator.mediaDevices.getUserMedia(
          constraints
        );
        const audioInput = this.audioContext.createMediaStreamSource(
          this.audioStream
        );
        const audioOutput = this.audioContext.destination;
        audioInput.connect(audioOutput);
        this.canvasContext = this.$refs.waveformCanvas.getContext("2d");
        this.analyserNode = this.audioContext.createAnalyser();
        audioInput.connect(this.analyserNode);
        this.drawWaveform();

        this.micchecking = true;
      } catch (error) {
        console.error("Error accessing microphone:", error);
        this.micchecking = false;
      }
    },
    async drawWaveWhilerecording() {
      this.miccheckingfirst = false;
      this.audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
      const constraints = {
        audio: {
          deviceId: this.selectedAudioDeviceId
        }
      };
      this.audioStream = await navigator.mediaDevices.getUserMedia(constraints);
      const audioInput = this.audioContext.createMediaStreamSource(
        this.audioStream
      );
      this.canvasContext = this.$refs.waveformCanvas.getContext("2d");
      this.analyserNode = this.audioContext.createAnalyser();
      audioInput.connect(this.analyserNode);
      this.drawWaveform();
    },
    async isMicrophoneMuted() {
      const constraints = {
        audio: {
          deviceId: this.selecteddeviceid
        }
      };
      await navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          this.selecteddeviceOn = stream.getAudioTracks()[0].muted;
          if (this.configuration && this.selecteddeviceOn) {
            alert(
              "Your microphone is muted; please check your system for troubleshooting."
            );
          }
        })
        .catch((err) => console.log(err));
    },
    async getDevices() {
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        devices.forEach((device) => {
          if (device.kind == "audioinput" && device.groupId != "") {
            this.AudioDevice.push({
              value: device.deviceId,
              text: device.label
            });
            this.AudioId = this.AudioDevice[0].text;
            this.selecteddeviceid = this.AudioDevice[0].value;
            this.isMicrophoneMuted();
          }
          if (device.kind == "audiooutput") {
            this.OutputDevice.push({
              value: device.deviceId,
              text: device.label
            });
            this.SpeakerId = this.OutputDevice[0].text;
          }
        });
        if (this.AudioDevice.length == 0) {
          this.$bvModal.show("locationmodal");
        }
      });
    },
    // async checkPermissionGranted(key) {
    //   navigator.mediaDevices.getUserMedia({
    //     video: true,
    //     audio: true,
    //   });
    //   try {
    //     const permission = await navigator.permissions.query({ name: key });
    //     if (permission.state === "granted") {
    //       this.$bvModal.hide("locationmodal");
    //       return true;
    //     }

    //     return false;
    //   } catch (error) {
    //     return false;
    //   }
    // },
    async startAudioStream() {
      try {
        navigator.mediaDevices
          .getUserMedia({
            audio: true
          })
          .then((stream) => {
            this.audioStream = stream;
            this.drawWaveWhilerecording();
            this.Checkaudio();
          });
      } catch (e) {
        console.log(e);
      }
    },
    Checkaudio() {
      const chunks = [];
      let options = {};
      if (MediaRecorder.isTypeSupported("audio/webm; codecs=vp9")) {
        options = {
          mimeType: "audio/webm; codecs=vp9",
          audioBitsPerSecond: 128000
        };
      } else if (MediaRecorder.isTypeSupported("audio/webm")) {
        options = { mimeType: "audio/webm", audioBitsPerSecond: 128000 };
      } else if (MediaRecorder.isTypeSupported("audio/mp4")) {
        options = { mimeType: "audio/mp4", audioBitsPerSecond: 128000 };
      } else {
        console.error("no suitable mimetype found for this device");
      }
      this.RecordedAudio = new MediaRecorder(this.audioStream, options);
      this.RecordedAudio.start();
      this.RecordedAudio.addEventListener("dataavailable", (event) => {
        console.log(event);
        chunks.push(event.data);
      });
      this.RecordedAudio.addEventListener("stop", () => {
        let blob = new Blob(chunks, { type: "audio/mp3" });

        this.recordedfile = blob;
        console.log(blob);
        let audio = document.querySelector("audio");
        console.log(audio);
        const audioURL = window.URL.createObjectURL(blob);
        audio.src = audioURL;
        audio.pause();
      });
    },
    Speakertest() {
      this.startedHearing = true;
      let blob = this.recordedfile;
      let audio = document.querySelector("audio");
      console.log(audio);
      console.log(blob);
      const audioURL = window.URL.createObjectURL(blob);
      audio.src = audioURL;
      audio.play();
    },
    stopHearingAnswer() {
      this.startedHearing = false;
      let audio = document.querySelector("audio");
      audio.pause();
    },
    stoppaudioStream: function() {
      this.RecordedAudio.stop();
      if (this.audioContext) {
        this.audioContext.close().then((data) => {
          console.log(data);
        });
      }
    },
    async retryOrSubmit(submit) {
      this.startedHearing = false;
      this.isResult = false;
      try {
        this.isLoading = true;
        let blob = this.recordedfile;
        let audio = document.querySelector("audio");
        const audioURL = window.URL.createObjectURL(blob);
        audio.src = audioURL;
        audio.pause();
        let payload = new FormData();
        payload.append("audio", this.recordedfile);
        payload.append("topicID", this.testDetails.topicID);
        payload.append("attemptNo", this.testDetails.attemptNo);
        payload.append("testID", this.testDetails.testID);
        payload.append("groupID", this.testDetails.groupID);
        payload.append("textConverted", this.finalTranscription);
        if (submit === true) {
          payload.append("submit", "true");
        }
        let res = await postCommunicationTestTesponse(payload);
        console.log(res);
        this.runtimeTranscription = "";
        this.transcription = [];
        this.recordedfile = "";
        if (submit === true) {
          window.removeEventListener("beforeunload", this.handleBeforeUnload);
          this.recognition.stop();
          this.audioContext.close();
          this.getCmTestDeatils();
          this.testSubmit=true;
          // this.$router.push({
          //   path: "/dosanddonts"
          // });
        } else {
          this.recognition.stop();
          this.remainingtimeleft = 45;
          this.remainingtimeValue = 0;
          this.getCmTestDeatils();
          this.startRecording();
          this.startAudioStream();
        }
        this.isLoading = true;
      } catch (error) {
        console.log(error);
      }
    },
    cmCompleteBtn() {
      this.$router.push({
        path: "/dosanddonts"
      });
    },
    leave() {
      window.removeEventListener("beforeunload", this.handleBeforeUnload);
      if(localStorage.getItem("fromCandidate")){
        window.close('','_parent','');
      }
      this.$router.push({
        path: "/",
        query: {
          uuid: this.uuid
        }
      });
    },
    async getCmTestDeatils() {
      this.isLoading = true;
      console.log(this.testDetails.topicID,
        this.testDetails.testID,
        this.userDetials.email,
        this.testDetails.attemptNo,
        this.testDetails.groupID)
      const res = await getCommunicationTestDetails(
        this.testDetails.topicID,
        this.testDetails.testID,
        this.userDetials.email,
        this.testDetails.attemptNo,
        this.testDetails.groupID
      ).catch((e) => {
        this.isLoading = false;
        if (e.response.status === 409) {
          this.$store.commit("sectionVUEX/saveIsCommunicationTestStatus", true);
        } else if (e.response.status === 500) {
          this.showRelogin = 500;
        }
      });
      if (res.status == 200) {
        this.isLoading = false;
        this.$store.commit("sectionVUEX/saveIsCommunicationTestStatus", false);
      }
      this.keyWords = res.data.data.keyWords;
      this.remainingNoOfAttempts = res.data.data.remainingNoOfAttempts;
      this.imgSrc = res.data.data.mediaResource[0].mediaLink;
    },
    startRecording() {
      console.log(this.recognition);
      this.remainingtimeInterval = setInterval(() => {
        this.remainingtimeValue = this.remainingtimeValue + 2.3;
        this.remainingtimeleft--;
      }, 1000);
      this.recognition.addEventListener("result", (event) => {
        const text = Array.from(event.results)
          .map((result) => result[0])
          .map((result) => result.transcript)
          .join("");
        this.runtimeTranscription = text;
      });
      this.recognition.addEventListener("end", (event) => {
        console.log("end", event);
        if (this.remainingtimeleft > 0) {
          if (this.runtimeTranscription != "") {
            this.transcription.push(this.runtimeTranscription);
            this.finalTranscription = this.transcription.join("\n");
            this.runtimeTranscription = "";
          }
          this.recognition.start();
        } else {
          if (this.runtimeTranscription != "") {
            this.transcription.push(this.runtimeTranscription);
            this.finalTranscription = this.transcription.join("\n");
            console.log(this.finalTranscription, "stop");
            this.runtimeTranscription = "";
          }
          this.recognizing = false;
        }
      });
      this.recognition.onstart = () => {
        this.recognizing = true;
        console.log("started");
      };
      this.recognition.addEventListener("soundstart", () => {
        this.isResult = true;
        console.log("Some sound is being received");
      });
      this.recognition.start();
    },
    upgrade() {
      alert("system error");
    }
  }
};
