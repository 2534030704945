<template>
  <div>
    <CommunicationTestLaptop v-if="isLaptop" />
    <MCommunicationTest v-else/>
  </div>
</template>

<script>
import CommunicationTestLaptop from './CommunicationTestLaptop.vue';
import MCommunicationTest from './mobileView/MCommunicationTest.vue';
export default {
  name: "communicationTest",
  components: {
    MCommunicationTest,
    CommunicationTestLaptop
  },
  data() {
    return {
      isLaptop:true
    }
  },
  mounted() {
    window.addEventListener("resize", this.checkScreenSize);
    this.checkScreenSize();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkScreenSize);
  },
  methods: {
    checkScreenSize() {
      this.isLaptop = window.innerWidth >= 1024;
    },
  },
};
</script>

<style>
@media only screen and (max-width: 1024px) {

  .screenSizeLap {

    display: none;

  }

  #ct-firstmodal___BV_modal_outer_ {

    display: none !important;

  }
  #teststarts___BV_modal_outer_ {
    display: none !important;

  }

}



@media only screen and (min-width: 1025px) {

  .screenSizeMobile {

    display: none;

  }

}
</style>